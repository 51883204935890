<template>
    <tr v-if="item && product && product.id > 0" :class="getClass">
        <td :colspan="smallScreen ? 2 : 1"><router-link :to="'/product/'+item.url">{{ product[prefs.lang].name }}</router-link>
            <template v-if="item.options">
                <template v-for="option in item.options">
                    <el-checkbox
                        v-if="group.id < 10 || (group.id >= 10 && option.wholesale)"
                        v-model="option.enabled"
                        class="prodOpt">
                            {{ option['name_'+prefs.lang] }} ({{ option.price_impact }} грн)
                    </el-checkbox>
                </template>
            </template>

            <span class="ready_at" v-if="isOver">
                <template v-if="cart.availQ(product.id) < 1">
                    <template v-if="product.next_batch">{{ txt[prefs.lang].will_avail }}: {{ readyAt(product.next_batch.ready_at) }}</template>
                    <template v-else>{{ txt[prefs.lang].unavail }}</template>
                </template>
                <template v-else>
                    {{ txt[prefs.lang].unavail }} {{ txt[prefs.lang].in_q }}<template v-if="product.next_batch">; {{ readyAt(product.next_batch.ready_at) }}</template>
                </template>
            </span>

        </td>

        <td>
            <el-button :disabled="item.q == 0" @click="item.q--"
               icon="el-icon-remove-outline" type="text" class="hidden-xs-only"></el-button>
            <input type="number" min="0" max="999" step="1"
               v-model.number="item.q"
                @keydown="cart.preventor($event)"
                placeholder="0"
                onfocus="setTimeout(() => {this.select()}, 1)">
            <el-button :disabled="item.q >= 999" @click="item.q++"
               icon="el-icon-circle-plus-outline" type="text"></el-button>
        </td>

        <td class="hidden-xs-only">×</td>

        <td>
            <span :class="getPriceClass">{{ product.price }}₴<br></span>
<!--            <b v-if="user && item.sp">{{ item.sp }}₴</b>-->
            <span :class="{ sp: user && item.sp !== null }">{{ cart.price_final(item) }}₴</span>
            <el-button v-if="user && item.sp === null" @click="item.sp = 0" icon="el-icon-edit" type="text"></el-button>
            <input v-if="user && item.sp !== null" type="number" min="0" max="9999" v-model.number="item.sp"
               @keydown.enter="setSpecialPrice"
               @keydown.escape="item.sp = null"
               placeholder="SP"
               onfocus="setTimeout(() => {this.select()}, 1)">
        </td>
        <td class="hidden-xs-only">=</td>
        <td class="hidden-xs-only"><span :class="getPriceClass">{{ cart.price_item_t(item) }}₴<br></span> {{ cart.price_total(item) }}₴</td>
<!--        item.t()-->
        <td><el-button @click="$emit('del', item.id)" icon="el-icon-delete" size="mini" type="text"></el-button></td>
    </tr>


<!--    <el-dialog title="Спец. цена" v-model="priceDialog">-->

<!--        <el-input v-model="specialPrice" type="number" placeholder="Цена" @keydown.enter="setSpecialPrice"></el-input>-->

<!--        <template #footer>-->
<!--            <span class="dialog-footer">-->
<!--              <el-button @click="priceDialog = false">Скасувати</el-button>-->
<!--              <el-button type="primary" @click="setSpecialPrice">Зберегти</el-button>-->
<!--            </span>-->
<!--        </template>-->

<!--    </el-dialog>-->

</template>

<script>
import { ref, computed, watchEffect } from 'vue'
import useCart from "@/use/cart"
import { txt } from '@/use/txt'

export default {
    // data() {
    //     return {
    //         nq: this.item.q
    //     }
    // },
    setup(props) {
        // console.log(props.item)
        // const nq = ref(props.item.q)
        // if (props.item) {
        const {cart, user, prefs, message, products, me, group} = useCart()

        const product = props.item ? cart.product(props.item.id) : null

        // isOver for styles (see cart for the main)
        const isOver = ref(0)
        watchEffect(() => {
            if (product) isOver.value = props.item && props.item.q > 0 && props.item.q > cart.availQ(product.id) ? 1 : 0
            // if (product) console.log(cart.availQ(product.id))
        })

        const getClass = computed(function () {
            if (props.item.q == 0) return 'zero'
            else if (isOver.value) return 'unav'
        })

        const getPriceClass = computed(() =>
            Number(cart.price_item_t(props.item)) != Number(cart.price_total(props.item)) ? 'crossed' : 'nodisp'
        )
        // const getPriceClass = computed(function() {
        //     if (item.price !== cart.price_total(item)) return 'crossed'
        //     else return 'nodisp'
        // })

        // const specialPrice = ref(0)
        // const priceDialog = ref(false)
        //
        // function setSpecialPrice() {
        //     cart.items[props.item.id].sp = specialPrice.value
        //     priceDialog.value = false
        // }
        const smallScreen = ref(window.innerWidth < 768)
        window.addEventListener('resize', () => {
            smallScreen.value = (window.innerWidth < 768)
        })


        function readyAt(date) {
            if (new Date(date + ' 00:00:00').getTime() <= new Date().getTime()) return txt[prefs.lang].soon;
            return date;
        }

        return { cart, prefs, user, message, getClass, getPriceClass, product, me, group, isOver, txt, smallScreen, readyAt }
        // }
    },
    props: {
        item: Object,
        // product: Object
    }
}
</script>

<style scoped>

    .zero {
        background: #fcfcfc;
        text-decoration: line-through;
        color: #aaa;
    }
    .zero a {
        color: #aaa;
    }
    .unav {
        background: #fdfdfd;
        color: #f44;
    }
    .unav a, .sp {
        color: #f44;
    }
    .ready_at {
        display: block;
        font-size: 0.8em;
    }

    .prodOpt {
        display: block;
    }

    .nodisp {
        display: none;
    }
</style>