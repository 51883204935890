<template>

    <div class="loader" v-if="loading" v-loading="loading"></div>

    <section v-if="product" id="product_section">

        <teleport to="head">
            <title>{{ product[prefs.lang].meta_title }}</title>
            <meta name="description" :content=product[prefs.lang].meta_description>
            <meta name="keywords" :content=product[prefs.lang].meta_keyword>
        </teleport>

        <teleport to="#top_benefit">
            <div v-if="product[prefs.lang].header" class="benefit_area">
                <h1>{{ product[prefs.lang].name }}</h1>
            </div>
        </teleport>

        <el-breadcrumb separator-class="el-icon-plus">
            <el-breadcrumb-item v-for="c in product.categories">
                <router-link :to="'/products/'+c.url">{{ c[prefs.lang].name }}</router-link>
            </el-breadcrumb-item>
            <el-breadcrumb-item></el-breadcrumb-item>
        </el-breadcrumb>

        <div class="product">

            <div class="product_image">
<!--                <img v-if="product.image" v-lazyload src='/catalog/sample.jpg' :data-url="require('@/assets/'+product.image)">-->
                <img v-if="product.image" v-lazyload src='/catalog/sample.jpg' :data-url="'/'+product.image">
                <img v-else scr='/catalog/sample.jpg'>
<!--                style="border: 1px solid green;"  -->
            </div>

            <h1>{{ product[prefs.lang].name }}</h1>

            <div class="price">
                <span v-if="product.special_price || rate < 1 && product.min_rate < 1" class="crossed">{{ product.price }}₴</span>
                <span v-if="cart.items[product.id]" class="final">{{ cart.price_final(cart.items[product.id]) }}₴</span>
                <span v-else class="final">{{ cart.price_final(product) }}₴</span>
            </div>

            <p v-if="product.quantity > 0">{{ txt[prefs.lang].avail }}</p>
            <p v-else>
                <span data-nosnippet>{{ txt[prefs.lang].unavail }}</span><br>
                <span v-if="product.next_batch" data-nosnippet><br>{{ txt[prefs.lang].will_avail }}: {{ readyAt(product.next_batch.ready_at) }}</span>
<!--                <span v-else data-nosnippet>{{ txt[prefs.lang].unknown }}</span>-->
                <br>

                <el-popover
                    placement="top-start"
                    :title=txt[prefs.lang].notify_na
                    :width="250"
                    trigger="hover"
                    :content=txt[prefs.lang].notify_single
                >
                    <template #reference>
                        <el-button @click="notify" size="mini" plain round :disabled="notified">
                            {{ txt[prefs.lang].notify_na }}
                        </el-button>
                    </template>
                </el-popover>
            </p>

            <p v-if="!product.status">{{ txt[prefs.lang].discontinued }}</p>

            <div v-if="product.status">
                <el-button
                    @click="cart.items[product.id].q--"
                    :disabled="!cart.items[product.id] || cart.items[product.id].q === 0"
                    icon="el-icon-minus" size="mini" plain circle></el-button>
                <input v-if="cart.items[product.id]" type="number" min="0" max="999"
                    v-model="cart.items[product.id].q"
                    @keydown="cart.preventor($event)"
                    placeholder="0"
                    onfocus="this.select();">
                <input v-else type="number" min="0" max="999" :value="0"
                    @keydown="cart.preventor($event)"
                    placeholder="0"
                    @change="addToCart(product, Number($event.target.value))" onfocus="this.select();">
                <el-button
                    @click="addToCart(product)"
                    :disabled="cart.items[product.id] && cart.items[product.id].q >= 999"
                    icon="el-icon-plus" size="mini" plain circle></el-button> шт
                <br>
                <span class="total" v-if="cart.items[product.id]">{{ cart.price_total(cart.items[product.id]) }}₴</span>
    <!--            <button @click="addToCart(product)">ADD 1</button>-->
            </div>

            <template v-if="product.options && !cart.items[product.id]">
                <div class="product_option" v-for="option in product.options">
                    <el-checkbox v-if="(!me || me.group.id < 10) || (me.group.id >= 10 && option.wholesale)" disabled>
                        {{ option['name_'+prefs.lang] }} ({{ option.price_impact }} грн)
                    </el-checkbox>
                </div>
            </template>

            <template v-if="cart.items[product.id] && cart.items[product.id].options">
                <div class="product_option" v-for="option in cart.items[product.id].options">
                    <el-checkbox v-if="(!me || me.group.id < 10) || (me.group.id >= 10 && option.wholesale)" v-model="option.enabled">
                        {{ option['name_'+prefs.lang] }} ({{ option.price_impact }} грн)
                    </el-checkbox>
                </div>
            </template>

            <el-button v-if="cart.items[product.id]" @click="delFromCart(product.id)" class="button_delete" icon="el-icon-delete" size="mini" plain circle></el-button>


        </div>

<!--        <div v-html="htmlDecode(product[prefs.lang].description)"></div>-->
        <div class="description" v-html="product[prefs.lang].description"></div>

        <ul class="attributes">
            <li v-for="a in product.attributes"><b>{{ a[prefs.lang] }}</b>: {{ a.pivot[prefs.lang] }}</li>
            <li v-if="product.categories.some(i => i.id == 59) && !product.categories.some(i => i.id == 70)">
                * {{ txt[prefs.lang].rspo }}
            </li>
        </ul>


        <div v-if="categoryBenefits" class="benefit_area ba_cat">
            <div class="cont" v-html="categoryBenefits"></div>
        </div>


        <section id="reviews">
            <el-divider><h3>{{ txt[prefs.lang].reviews }}</h3></el-divider>

            <el-form v-if="me" :model="review" :rules="rules">
                <el-form-item prop="rating">
                    <el-rate v-model="review.rating" prop="rating"></el-rate>
                </el-form-item>
                <el-form-item prop="text">
                    <el-input v-model="review.text" type="textarea" :placeholder=txt[prefs.lang].review_label autosize></el-input>
                </el-form-item>
                <el-button plain round size="small" @click="addReview">{{ txt[prefs.lang].send }}</el-button>
            </el-form>

            <p v-else>
                {{ txt[prefs.lang].pls_login }} {{ txt[prefs.lang].to_review }}: <el-button @click="$emit('showLogin')" size="mini" plain round>{{ txt[prefs.lang].login }}</el-button>
            </p>

            <div v-if="product.reviews" class="reviews">
                <p v-for="r in product.reviews">
                    <b>{{ r.author }}</b>:<br>
                    {{ r.text }}<br>
                    {{ txt[prefs.lang].rating }}: <b>{{ r.rating }}</b>
                </p>
            </div>

        </section>

    </section>

    <template v-else-if="!loading">
        <teleport to="head">
            <title>{{ txt[prefs.lang].not_found }}</title>
        </teleport>

        <h1 class="big">{{ txt[prefs.lang].not_found }}</h1>
        <div class="centered">
            <router-link to="/products/all"><el-button type="success" round>{{ txt[prefs.lang].products }}</el-button></router-link>
        </div>

    </template>


</template>



<script>
import useCart from "@/use/cart"
import {useQuery, useResult, useMutation} from '@vue/apollo-composable'
import productQuery from '../graphql/product.query.gql'
import { ref, reactive, watch, watchEffect, computed } from 'vue'
import { txt } from '@/use/txt'
import { gql } from '@apollo/client'
import LazyLoadDirective from "@/components/LazyLoadDirective.vue";

export default {
    setup(props, context) {

        const item = cart && product ? cart.items[product.id] : null
        const { prefs, rate, langs, message, cart, me, addToCart, delFromCart, categories } = useCart()

        const review = reactive({
            rating: null,
            text: ''
        })

        let rules = {
            rating: [
                {required: true, message: txt[prefs.lang].required, trigger: 'submit'},
            ],
            text: [
                {required: true, message: txt[prefs.lang].required, trigger: 'blur'},
                {min: 10, message: txt[prefs.lang].too_short, trigger: 'change'},
            ]
        }

        const isFilled = () => review.rating && review.text.length >= 10

        const vars = ref({ url: props.url })
        const { result, error, loading } = useQuery(productQuery, vars)
        const product = useResult(result, null, data => data.product)

        // if (product && product.value) console.log(product.value)
        // const opts = ref({})

        const { mutate: add_review, loading: reviewLoading } = useMutation(gql`
            mutation($product_id: ID!, $rating: Int!, $text: String!) {
                review: addReview(product_id: $product_id, rating: $rating, text: $text) {
                    id
                    author
                    text
                    rating
                    created_at
                }
            }
        `)

        function addReview() {
            if (!isFilled()) {
                message('!', txt[prefs.lang].pls_check+' ('+txt[prefs.lang].rating+'?)', 'warning')
                return
            }
            add_review({ product_id: product.value.id, rating: review.rating, text: review.text }).then(r => {
                if (r.data.review) {
                    message(txt[prefs.lang].thanks, txt[prefs.lang].review_mes, 'success')
                    review.rating = null
                    review.text = ''
                }
                else message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'error')
            }).catch(e => {
                message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'error')
            })
        }


        const notified = ref(false)

        const { mutate: add_notification, loading: addNotifLoading, onError } = useMutation(gql `
            mutation($products: String!) {
                ntf: addNotification(products: $products) {
                    id
                }
            }`)

        function notify() {
            if (me.value) {
                add_notification({ products: product.value.id+':1', customer_id: me.value.id }).then(r => {
                    if (r.data.ntf) {
                        notified.value = true
                        message(txt[prefs.lang].cart, txt[prefs.lang].notify_single, 'success')
                    }
                    else message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'error')
                }).catch(e => {
                    message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'error')
                })
            }
            else context.emit('showLogin')
        }

        function htmlDecode(input){
            var e = document.createElement('div');
            e.innerHTML = input;
            return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
        }


        function getCategoryBenefits() {
            for (let prodcat of product.value.categories) {
                if (prodcat.id == 67) continue;
                let cat = categories.value.find(i => i.id == prodcat.id)
                if (cat[prefs.lang].description) return cat[prefs.lang].description.split('</h1>')[1]
            }
            return null
        }

        const categoryBenefits = ref('<i>no bens</i>')
        if (product && product.value) categoryBenefits.value = getCategoryBenefits()
        watch(product, () => { categoryBenefits.value = getCategoryBenefits() })


        function readyAt(date) {
            if (new Date(date + ' 00:00:00').getTime() <= new Date().getTime()) return txt[prefs.lang].soon;
            return date;
        }

        return { product, rate, review, rules, addReview, item, error, loading, prefs, langs, cart, me, addToCart, delFromCart, htmlDecode, txt, categoryBenefits, notify, notified, readyAt }
    },
    props: {
        url: String
    },
    directives: {
        lazyload: LazyLoadDirective
    }

}
</script>

<style scoped>
    .product_image {
        /*width: 100%;*/
        padding-top: 1em;
        /*overflow: hidden;*/
    }
    .product_image img {
        max-width: 100%;
        height: auto !important;
        /*aspect-ratio: 1;*/
        /*display: block;*/
    }
    #product_section {
        max-width: 900px;
        margin: 0 auto;
    }
    .description {
        margin: 3em auto;
        clear: both;
    }
    .description ul {
        margin-bottom: 2em !important;
        list-style: circle !important;
    }
    #reviews {
        clear: both;
        margin-top: 4em;
    }
    .reviews p { padding: 1em; }
    .reviews p:nth-child(2n) { background: #fff; }
    .reviews p:nth-child(2n+1) { background: #f8f8f8; }

    .attributes {
        clear: both;
        padding: 1em 2em;
        background-color: #fafafa;
    }
    .attributes li {
        padding: 0.5em 0;
    }
    .product {
        text-align: center;
    }
    .product_image img {
        width: 500px;
        height: 500px;
        margin-top: 1em;
    }
    .product_option {
        margin: 0.7em;
    }
    .button_delete {
        margin-top: 0.7em;
    }
    .total {
        color: #ccc;
    }
    .price {
        margin: 0.3em;
    }
    .crossed {
        text-decoration: line-through;
        margin-right: 0.5em;
        color: #ccc;
    }
</style>