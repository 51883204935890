<template>

    <div class="loader" v-if="loading" v-loading="loading"></div>

    <teleport to="head">
        <title>{{ txt[prefs.lang].title }}</title>
        <meta name="description" :content=txt[prefs.lang].description>
        <meta name="keywords" :content=txt[prefs.lang].keywords>
    </teleport>

    <teleport to="#top_benefit">
        <div class="benefit_area ba_index">
<!--            <h1>PLANTA</h1>-->
<!--            <img src="@/assets/m-logo.png" style="width: 5em;">-->
<!--            <h3>{{ txt[prefs.lang].description }}</h3>-->
            <div class="cont" style="max-width: 300px;">
                <img src="/m-logo.png" style="width: 200px; margin: 50px;">
                <ul>
                    <li v-for="i in txt[prefs.lang].descs_short"><i class="el-icon-check"></i> {{ i }}</li>
                </ul>
                <p class="centered">
                    <router-link to="/products/all"><el-button type="success" round>{{ txt[prefs.lang].all_products }}</el-button></router-link>&nbsp;
                    <router-link to="/reviews"><el-button type="success" round plain>{{ txt[prefs.lang].reviews }}</el-button></router-link>
                </p>
            </div>
        </div>
    </teleport>

    <section class="narrow">
        <h3>{{ txt[prefs.lang].description }}</h3>

        <ul style="max-width: 850px; margin: 2em auto;">
            <li v-for="i in txt[prefs.lang].descs_long"><i class="el-icon-check"></i> {{ i }}</li>
        </ul>

        <p class="centered">
            <router-link to="/products/all"><el-button type="success" round>{{ txt[prefs.lang].all_products }}</el-button></router-link>&nbsp;
            <router-link to="/reviews"><el-button type="success" round plain>{{ txt[prefs.lang].reviews }}</el-button></router-link>&nbsp;
            <router-link to="/contacts"><el-button round>{{ txt[prefs.lang].contacts }}</el-button></router-link>
        </p>
    </section>


</template>

<script>
import { txt } from '@/use/txt'
import useCart from "@/use/cart"

export default {
    setup() {
        const { prefs } = useCart()

        return { txt, prefs }
    }
}
</script>