<template>
<!--    :body-style="{ padding: '10px' }" -->
    <template v-if="product && ( (product.categories.some(i => i.id == category_id) && prodFilter.trim().length < 2) ||
        prodFilter.trim().length && (  product.ru.name.toLowerCase().includes(prodFilter.toLowerCase())
        || product.uk.name.toLowerCase().includes(prodFilter.toLowerCase())
        || product.url.includes(prodFilter.toLowerCase()) ) )">

    <el-col v-if="prefs.show_as === 'card'" :xs="24" :sm="12" :md="8" :lg="8" :xl="6">

        <el-card shadow="hover" class="CatalogItem">
            <router-link :to="'/product/'+product.url">
                <img v-if="product.image" v-lazyload src='/catalog/sample.jpg' :data-url="'/'+product.image">
                <img v-else scr='/catalog/sample.jpg'>
            </router-link>
            <div class="CatalogItemInfo">
                <router-link :to="'/product/'+product.url">{{product[prefs.lang].name}}</router-link>
                <div class="price">
                    <span v-if="product.special_price || rate < 1 && product.min_rate < 1" class="crossed">{{ product.price }}₴</span>
                    <span class="final">{{ cart.price_final(product)}}₴</span>
                </div>
                <p class="avail" v-if="product.quantity <= 0">
                    <template v-if="product.next_batch">{{ txt[prefs.lang].will_avail }}: {{ readyAt(product.next_batch.ready_at) }}</template>
                    <template v-else>{{ txt[prefs.lang].unavail }}</template>
                </p>
    <!--            <p v-if>{{ txt[prefs.lang].unavail }}</p>-->
                <p v-if="!product.status">{{ txt[prefs.lang].discontinued }}</p>

                <div v-if="product.status">
                    <el-button
                        @click="cart.items[product.id].q--"
                        :disabled="!cart.items[product.id] || cart.items[product.id].q == 0"
                        icon="el-icon-remove-outline" type="text"></el-button>
                    <input type="number" min="0" max="999" step="1"
                       :value="cart.items[product.id] !== undefined ? cart.items[product.id].q : null"
                       @change="cart.items[product.id] === undefined ? addToCart(product, Number($event.target.value)) : cart.items[product.id].q = $event.target.value"
                       @keydown="cart.preventor($event)"
                       placeholder="0"
                       onfocus="setTimeout(() => {this.select()}, 1)">
                    <el-button
                        @click="addToCart(product)"
                        :disabled="cart.items[product.id] && cart.items[product.id].q >= 999"
                        icon="el-icon-circle-plus-outline" type="text"></el-button>
                </div>
            </div>
        </el-card>
    </el-col>


    <tr v-else-if="prefs.show_as === 'list'" class="item_tr">
<!--        <td class="clickable" @click="toProduct(product.url)"><router-link :to="'/product/'+product.url"><img :src="require('@/assets/'+product.image)"></router-link></td>-->
        <td class="clickable" @click="toProduct(product.url)">
            <router-link :to="'/product/'+product.url">
                <img v-if="product.image" v-lazyload src="/catalog/sample.jpg" :data-url="'/'+product.image">
                <img v-else scr='/catalog/sample.jpg'>
                    {{product[prefs.lang].name}}</router-link>
            <span class="avail" v-if="product.quantity <= 0"> –
                <span v-if="product.next_batch" data-nosnippet>{{ txt[prefs.lang].will_avail }} {{ readyAt(product.next_batch.ready_at) }}</span>
                <span v-else data-nosnippet>{{ txt[prefs.lang].unavail }}</span>
            </span>
        </td>
        <td class="price">
            <span v-if="product.special_price || rate < 1 && product.min_rate < 1" class="crossed">{{ product.price }}₴</span>
            <span class="final">{{ cart.price_final(product)}}₴</span>
        </td>
        <td v-if="product.status" class="hidden-xs-only">
            <el-button
                @click="cart.items[product.id].q--"
                :disabled="!cart.items[product.id] || cart.items[product.id].q == 0"
                icon="el-icon-remove-outline" type="text"></el-button>
            <input type="number" min="0" max="999" step="1"
               :value="cart.items[product.id] !== undefined ? cart.items[product.id].q : null"
               @change="cart.items[product.id] === undefined ? addToCart(product, Number($event.target.value)) : cart.items[product.id].q = $event.target.value"
               @keydown="cart.preventor($event)"
               placeholder="0"
               onfocus="setTimeout(() => {this.select()}, 1)">
            <el-button
                @click="addToCart(product)"
                :disabled="cart.items[product.id] && cart.items[product.id].q >= 999"
                icon="el-icon-circle-plus-outline" type="text"></el-button>
        </td>
        <td v-if="cart.items[product.id]" class="hidden-sm-and-up">{{ cart.items[product.id].q }}</td>
        <td v-else class="hidden-sm-and-up">0</td>
        <td v-if="product.status" class="hidden-sm-and-up">
            <el-button
                @click="addToCart(product)"
                :disabled="cart.items[product.id] && cart.items[product.id].q >= 999"
                icon="el-icon-circle-plus" type="text"></el-button>
        </td>
        <td v-else></td>

    </tr>

    </template>
</template>

<script>
import useCart from "@/use/cart"
import { txt } from '@/use/txt'
import router from '@/router/index'
import LazyLoadDirective from "@/components/LazyLoadDirective.vue";

export default {
    setup() {
        const { cart, prefs, rate, addToCart, delFromCart } = useCart()

        function toProduct(url) {
            router.push({ path: '/product/'+url})
        }

        function readyAt(date) {
            if (new Date(date + ' 00:00:00').getTime() <= new Date().getTime()) return txt[prefs.lang].soon;
            return date;
        }

        return { cart, prefs, rate, addToCart, delFromCart, txt, toProduct, readyAt }
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        category_id: [String, Number],
        prodFilter: String,
    },
    directives: {
        lazyload: LazyLoadDirective
    }
}
</script>

<style scoped>
    .avail {
        font-size: 0.9em;
        color: #888;
    }

</style>