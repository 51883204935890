<template>

  <el-menu mode="horizontal"
       @select="handleSelect"
       :default-active="router.currentRoute.value.path"
       class="main_menu hidden-xs-only"
       background-color="#76c043"
       text-color="#fff"
       active-text-color="#fff">

    <el-menu-item index="/"><img src="@/assets/m-logo.png" style="height: 33%"></el-menu-item>

    <el-submenu index="/about">
      <template #title>{{ txt[prefs.lang].about_us }}</template>
      <el-menu-item index="/">{{ txt[prefs.lang].who_we_are }}</el-menu-item>
      <el-menu-item index="/ingredients">{{ txt[prefs.lang].ingredients }}</el-menu-item>
      <el-menu-item index="/reviews">{{ txt[prefs.lang].reviews }}</el-menu-item>
      <el-menu-item index="/discount">{{ txt[prefs.lang].discount }}</el-menu-item>
      <el-menu-item index="/partnership">{{ txt[prefs.lang].partnership }}</el-menu-item>
      <el-menu-item index="/contacts">{{ txt[prefs.lang].contacts }}</el-menu-item>
    </el-submenu>

    <el-submenu index="/products">
      <template #title>{{ txt[prefs.lang].products }}</template>
      <el-menu-item v-for="c in mainCategories" :index="'/products/'+c.url">{{ c[prefs.lang].name }}</el-menu-item>
    </el-submenu>

    <el-tooltip :content="cart.total()+' грн'" placement="bottom">
      <el-menu-item index="/cart" :disabled="cart.q() <= 0">
        <i class="el-icon-shopping-cart-1" color="#ffffff"></i>
        <el-badge v-if="ch_order" is-dot class="item" type="warning"></el-badge>
        <el-badge :value=cart.q() :max=999 class="item" :type="cart.isOver() ? 'danger' : 'primary'"></el-badge>
      </el-menu-item>
    </el-tooltip>

    <!--    <el-submenu index="/contacts">-->
    <!--      <template #title>(098) 555 01 22</template>-->
    <!--      <el-menu-item><a href="tel:+380985550122" title="Kyivstar">(098) 555 01 22</a></el-menu-item>-->
    <!--      <el-menu-item><a href="tel:+380660090122" title="Vodafone">(066) 009 01 22</a></el-menu-item>-->
    <!--      <el-menu-item><a href="tel:+380930500122" title="Lifecell">(093) 050 01 22</a></el-menu-item>-->
    <!--      <el-menu-item><router-link to="/contacts">{{ txt[prefs.lang].all_contacts }}</router-link></el-menu-item>-->
    <!--    </el-submenu>-->

    <template v-if="me && me.email && !user">
      <el-menu-item index="/profile"><i class="el-icon-user"></i> {{ me.firstname }}</el-menu-item>
    </template>

    <el-menu-item v-else-if="!user" @click="loginDialog = true">{{ txt[prefs.lang].login }}</el-menu-item>

    <template v-else-if="user">
      <template v-if="me">
        <el-menu-item index="/profile"><i class="el-icon-user"></i> {{ me.firstname }}</el-menu-item>
        <el-menu-item @click="clearCustomer" index="!del"><i class="el-icon-close"></i></el-menu-item>
        <el-menu-item @click="customerDialog = true" index="!change"><i class="el-icon-s-custom"></i></el-menu-item>
      </template>
      <el-menu-item v-else @click="customerDialog = true" index="!change"><i class="el-icon-s-custom"></i>Обрати</el-menu-item>
    </template>

    <el-submenu index="!lang">
      <template #title>{{ txt[prefs.lang].lang }}</template>
      <el-menu-item index="!uk" @click="prefs.lang='uk'">Укр</el-menu-item>
      <el-menu-item index="!ru" @click="prefs.lang='ru'">Рус</el-menu-item>
    </el-submenu>

    <el-menu-item v-if="baseUrl.includes('localhost')">❗️ L O C A L ❗️</el-menu-item>

  </el-menu>



  <el-menu mode="horizontal"
     @select="handleSelect"
     :default-active="router.currentRoute.value.path"
     class="main_menu hidden-sm-and-up"
     menu-trigger="click"
     background-color="#76c043"
     text-color="#fff"
     active-text-color="#fff">

    <el-menu-item index="/"><img src="@/assets/m-logo.png" style="height: 25%"></el-menu-item>

    <el-tooltip :content="cart.total()+' грн'" placement="bottom">
      <el-menu-item index="/cart" :disabled="cart.q() <= 0">
        <i class="el-icon-shopping-cart-1" color="#ffffff"></i>
        <el-badge v-if="ch_order" is-dot class="item" type="warning"></el-badge>
        <el-badge :value=cart.q() :max=999 class="item" :type="cart.isOver() ? 'danger' : 'primary'"></el-badge>
      </el-menu-item>
    </el-tooltip>

    <el-submenu index="/">
      <template #title><i class="el-icon-menu" style="color: #fff;"></i></template>
      <template v-if="me && me.email && !user">
        <el-menu-item index="/profile">{{ txt[prefs.lang].profile }}</el-menu-item>
      </template>

      <el-menu-item v-else-if="!user" @click="loginDialog = true">{{ txt[prefs.lang].login }}</el-menu-item>

      <template v-else-if="user">
        <el-menu-item index="!user"><a href="/green-treen/" target="_blank"><i class="el-icon-setting"></i>ADMIN</a></el-menu-item>
        <el-submenu v-if="me" index="!customer">
          <template #title><i class="el-icon-user"></i> {{ me.firstname }}</template>
          <el-menu-item index="/profile"><i class="el-icon-user"></i> {{ me.firstname }}'s Profile</el-menu-item>
          <el-menu-item @click="customerDialog = true" index="!change"><i class="el-icon-s-custom"></i> Другой клиент</el-menu-item>
          <el-menu-item @click="clearCustomer" index="!del"><i class="el-icon-close"></i> Очистить</el-menu-item>
        </el-submenu>
        <el-menu-item v-else @click="customerDialog = true" index="!change"><i class="el-icon-user"></i>Выбрать</el-menu-item>
      </template>

      <el-menu-item index="/products/all">{{ txt[prefs.lang].products }}</el-menu-item>
      <!--      <el-menu-item v-for="c in mainCategories" :index="'/products/'+c.url">{{ c[prefs.lang].name }}</el-menu-item>-->
      <el-menu-item index="/ingredients">{{ txt[prefs.lang].ingredients }}</el-menu-item>
      <el-menu-item index="/reviews">{{ txt[prefs.lang].reviews }}</el-menu-item>
      <el-menu-item index="/discount">{{ txt[prefs.lang].discount }}</el-menu-item>
      <el-menu-item index="/partnership">{{ txt[prefs.lang].partnership }}</el-menu-item>
      <el-menu-item index="/contacts">{{ txt[prefs.lang].contacts }}</el-menu-item>
    </el-submenu>

    <!--    <el-submenu index="/contacts">-->
    <!--      <template #title>(098) 555 01 22</template>-->
    <!--      <el-menu-item><a href="tel:+380985550122" title="Kyivstar">(098) 555 01 22</a></el-menu-item>-->
    <!--      <el-menu-item><a href="tel:+380660090122" title="Vodafone">(066) 009 01 22</a></el-menu-item>-->
    <!--      <el-menu-item><a href="tel:+380930500122" title="Lifecell">(093) 050 01 22</a></el-menu-item>-->
    <!--      <el-menu-item><router-link to="/contacts">{{ txt[prefs.lang].all_contacts }}</router-link></el-menu-item>-->
    <!--    </el-submenu>-->

    <!--    <template v-if="me && me.email && !user">-->
    <!--      <el-menu-item index="/profile"><i class="el-icon-user"></i> {{ me.firstname }}</el-menu-item>-->
    <!--      <el-menu-item index="!logout" @click="logout">{{ txt[prefs.lang].logout }}</el-menu-item>-->
    <!--    </template>-->

    <!--    <el-menu-item v-else-if="!user" @click="loginDialog = true">{{ txt[prefs.lang].login }}</el-menu-item>-->

    <!--    <template v-else-if="user">-->
    <!--      <el-menu-item index="!user"><i class="el-icon-setting"></i>ADMIN</el-menu-item>-->
    <!--      <el-submenu v-if="me" index="!customer">-->
    <!--        <template #title><i class="el-icon-user"></i> {{ me.firstname }}</template>-->
    <!--        <el-menu-item index="/profile"><i class="el-icon-user"></i> {{ me.firstname }}'s Profile</el-menu-item>-->
    <!--        <el-menu-item @click="customerDialog = true" index="!change"><i class="el-icon-s-custom"></i> Другой клиент</el-menu-item>-->
    <!--        <el-menu-item @click="clearCustomer" index="!del"><i class="el-icon-close"></i> Очистить</el-menu-item>-->
    <!--      </el-submenu>-->
    <!--      <el-menu-item v-else @click="customerDialog = true" index="!change"><i class="el-icon-user"></i>Выбрать</el-menu-item>-->
    <!--    </template>-->

    <!--    <el-submenu index="!lang">-->
    <!--      <template #title>{{ txt[prefs.lang].lang }}</template>-->
    <!--      <el-menu-item index="!uk" @click="prefs.lang='uk'">Укр</el-menu-item>-->
    <!--      <el-menu-item index="!ru" @click="prefs.lang='ru'">Рус</el-menu-item>-->
    <!--    </el-submenu>-->

  </el-menu>


  <section id="top_benefit"></section>

  <section id="main">

<!--    <el-alert-->
<!--      v-if="settings.message"-->
<!--      type="info"-->
<!--      :description=settings.message-->
<!--      show-icon>-->
<!--    </el-alert>-->

    <el-alert
      v-if="ch_order"
      :title=txt[prefs.lang].order_change_mode
      type="warning"
      :description="txt[prefs.lang].order_change_mode_i"
      show-icon
      class="change_mode"
      :closable="false">
    </el-alert>

    <router-view
      @showLogin="(v, v2) => { loginDialog = v ? v : true; whaitavail = v2 }"
      @getMe="getMe"
      @reset="reset"
      @changeCustomer="customerDialog = true"
      @logout="logout"
    />

  </section>


  <section id="footer">
    <el-container>
      <el-row>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <h3>{{ txt[prefs.lang].about_us }}</h3>
          <ul>
            <li><router-link to="/">{{ txt[prefs.lang].who_we_are }}</router-link></li>
            <li><router-link to="/discount">{{ txt[prefs.lang].discount }}</router-link></li>
            <li><router-link to="/reviews">{{ txt[prefs.lang].reviews }}</router-link></li>
            <li><router-link to="/partnership">{{ txt[prefs.lang].partnership }}</router-link></li>
            <li><router-link to="/contacts">{{ txt[prefs.lang].contacts }}</router-link></li>
            <li><router-link to="/privacy">{{ txt[prefs.lang].privacy }}</router-link></li>
          </ul>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <h3>{{ txt[prefs.lang].products }}</h3>
          <ul>
            <li v-for="c in mainCategories">
              <router-link :to="'/products/'+c.url">{{ c[prefs.lang].name }}</router-link>
            </li>
          </ul>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <h3>{{ txt[prefs.lang].ingredients }}</h3>
          <ul>
            <li><router-link to="/manipulation">{{ txt[prefs.lang].wo_manipulation }}</router-link></li>
            <li><router-link to="/opened">{{ txt[prefs.lang].opened_ingredients }}</router-link></li>
            <li><router-link to="/palm-oil">{{ txt[prefs.lang].palm_oil }}</router-link></li>
            <li><router-link to="/ethoxylated">{{ txt[prefs.lang].ethoxylated }}</router-link></li>
            <li><router-link to="/methylisothiazolinone">{{ txt[prefs.lang].methylisothiazolinone }}</router-link></li>
            <li><router-link to="/sodium-cocoyl-isethionate">Sodium Cocoyl Isethionate</router-link></li>
            <li><router-link to="/surfactants">{{ txt[prefs.lang].surfactants }}</router-link></li>
            <li><router-link to="/extracts">{{ txt[prefs.lang].extracts }}</router-link></li>
          </ul>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <h3>{{ txt[prefs.lang].contacts }}</h3>
          <ul>
            <li><a class="extls" href="tel:+380985550122" title="Kyivstar">(098) 555 01 22</a></li>
            <li><a class="extls" href="tel:+380660090122" title="Vodafone">(066) 009 01 22</a></li>
            <li><a class="extls" href="tel:+380930500122" title="Lifecell">(093) 050 01 22</a></li>
            <li><a class="extls" target="_blank" href="sgnl://signal.me/#p/+380985550122" title="Signal">Signal</a></li>
            <li><a class="extls" target="_blank" href="viber://chat?number=380985550122" title="Viber">Viber</a></li>
            <li><a class="extls" style="text-decoration-line: line-through; color: #666;" target="_blank" href="https://kremlingram.org/ua">Telegram</a></li>
          <li>&nbsp;</li>
            <li>{{ txt[prefs.lang].sm }}</li>
            <li><a class="extls" target="_blank" href="https://www.instagram.com/planta.eco/">Instagram</a></li>
            <li><a class="extls" target="_blank" href="https://www.facebook.com/planta.eco">Facebook</a></li>
          <li>&nbsp;</li>
          <li><router-link to="/contacts">{{ txt[prefs.lang].all_contacts }}</router-link></li>

          </ul>
        </el-col>
      </el-row>
    </el-container>

<!--    <a href="/#top" class="back-to-top">^TOP!</a>-->

  </section>

  <div v-if="offline" class="bottom">{{ txt[prefs.lang].offline }}</div>

    <div v-if="cartEventFooter" class="bottom" style="z-index: 1000;">
        <router-link to="/cart"><i class="el-icon-shopping-cart-1" color="#ffffff"></i> {{ txt[prefs.lang].cart }}</router-link>
        <template v-if="me && !cart.isOver()">
            &nbsp;{{ txt[prefs.lang].or }}
            <router-link to="/checkout">{{ txt[prefs.lang].to_checkout }}</router-link>
        </template>
    </div>


  <el-dialog :title=txt[prefs.lang].login v-model="loginDialog" :fullscreen="smallScreen" top="7vh" center>

    <template v-if="loginDialog === true || loginDialog === 'guest'">
<!--    <p class="centered"><b>{{ txt[prefs.lang].login_with }}</b></p>-->
    <div class="btns centered">
      <el-button class="google-btn" @click="socialLogin('google')">
        <img src="@/assets/google.svg"> <span>Google</span></el-button>
    </div>
    <p class="centered">{{ txt[prefs.lang].soc_reg }}<br>{{ txt[prefs.lang].reg_pol }} <router-link to="/privacy" @click="loginDialog = false">{{ txt[prefs.lang].policy }}</router-link>.</p>
<!--    <br>-->
    <el-divider>{{ txt[prefs.lang].or }}</el-divider>

    <el-form>
      <el-form-item :label=txt[prefs.lang].email>
        <el-input v-model="form.email" :placeholder=txt[prefs.lang].email autofocus clearable></el-input>
      </el-form-item>
      <el-form-item :label=txt[prefs.lang].password>
        <el-input v-model="form.password" @keydown.enter="signIn" :placeholder=txt[prefs.lang].password show-password></el-input>
      </el-form-item>

    </el-form>

    <p v-if="loginError" class="red centered">{{ txt[prefs.lang][loginError] }}</p>

<!--    <template #footer>-->
        <div class="centered">
<!--          <el-button @click="loginDialog = false" plain round>{{ txt[prefs.lang].cancel }}</el-button>-->
          <el-button @click="loginDialog = 'signUp'" plain round>{{ txt[prefs.lang].sign_up }}</el-button>
          <el-button type="success" @click="signIn" :loading="loginLoading" round>{{ txt[prefs.lang].login }}</el-button>
          <el-button v-if="loginError" @click="loginDialog = 'reset'" plain round>{{ txt[prefs.lang].forgot }}</el-button>
          <template v-if="loginDialog === 'guest'">
            <el-divider>{{ txt[prefs.lang].or }}</el-divider>
            <el-button @click="guest_checkout" round>{{ txt[prefs.lang].guest_checkout }}</el-button>
            <br>{{ txt[prefs.lang].guest_checkout_i }}
          </template>
        </div>
<!--    </template>-->
    </template>


    <template v-else-if="loginDialog === 'reset'">
      <h3>{{ txt[prefs.lang].reset }}</h3>
      <el-form>
        <el-form-item :label=txt[prefs.lang].email>
          <el-input v-model="form.email" @keydown.enter="reset(form.email)" :placeholder=txt[prefs.lang].email clearable></el-input>
        </el-form-item>
      </el-form>

      <p v-if="isFilledWrong" class="red centered">{{ txt[prefs.lang][isFilledWrong] }}</p>
      <p v-if="resetError && !isFilledWrong" class="red centered">{{ txt[prefs.lang][resetError] }}</p>

      <div class="centered">
        <el-button @click="loginDialog = true" plain round>{{ txt[prefs.lang].cancel }}</el-button>
        <el-button type="success" @click="reset(form.email)" :disabled="isFilledWrong" :loading="loginLoading" round>{{ txt[prefs.lang].reset }}</el-button>
      </div>
    </template>

    <div v-else-if="loginDialog === 'reset_'" class="centered">
      <h2>{{ txt[prefs.lang].reset }}</h2>
      <p class="centered">{{ txt[prefs.lang].check_mail }}<br><br>🌿</p>
      <el-button @click="loginDialog = false" plain round>OK</el-button>
    </div>


    <template v-if="loginDialog === 'signUp'">
      <el-form>
        <el-form-item :label=txt[prefs.lang].email>
          <el-input v-model="form.email" :placeholder=txt[prefs.lang].email clearable></el-input>
        </el-form-item>
        <el-form-item :label=txt[prefs.lang].password>
          <el-input v-model="form.password" @keydown.enter="signUp" :placeholder=txt[prefs.lang].password show-password></el-input>
        </el-form-item>
        <el-form-item :label=txt[prefs.lang].password_confirm>
            <el-input v-model="form.password_confirm" @keydown.enter="signUp" :placeholder=txt[prefs.lang].password_confirm show-password></el-input>
        </el-form-item>
      </el-form>

      <p class="centered">{{ txt[prefs.lang].reg_pol }} <router-link to="/privacy" @click="loginDialog = false">{{ txt[prefs.lang].policy }}</router-link>.</p>
      <p v-if="isFilledWrong" class="red centered">{{ txt[prefs.lang][isFilledWrong] }}</p>
      <p v-if="signUpError && !isFilledWrong" class="red centered">{{ txt[prefs.lang][signUpError] }}</p>

      <div class="centered">
        <el-button @click="loginDialog = true" plain round>{{ txt[prefs.lang].cancel }}</el-button>
        <el-button type="success" @click="signUp" :disabled="isFilledWrong" :loading="loginLoading" round>{{ txt[prefs.lang].signup }}</el-button>
      </div>
    </template>

    <div v-else-if="loginDialog === 'signUp_'" class="centered">
      <h2>{{ txt[prefs.lang].signed_up }}</h2>
      <p class="centered">{{ txt[prefs.lang].confirm_mail }}<br><br>🌿</p>
      <el-button @click="loginDialog = false" plain round>OK</el-button>
    </div>

  </el-dialog>


  <el-dialog title="Вибір" v-model="customerDialog" :fullscreen="smallScreen">

    <el-select filterable clearable remote v-model="customer" :remote-method="getCustomers" placeholder="Клієнт" style="width: 100%;">
      <el-option :value=0 label="Незарег"></el-option>
      <el-option
          v-for="customer in customers"
          :value=customer.id
          :label="customer.lastname+' '+customer.firstname+' – '+customer.email+' – '+customer.id">
      </el-option>
    </el-select>

    <template #footer>
        <span class="dialog-footer">
          <el-button @click="customerDialog = false" plain round>{{ txt[prefs.lang].cancel }}</el-button>
          <el-button type="success" @click="chooseCustomer" round>{{ txt[prefs.lang].save }}</el-button>
        </span>
    </template>

  </el-dialog>

</template>

<script>
import useCart, {eventEmitter, eventListener} from "@/use/cart"
import router from '@/router/index'
import axios from "axios"
import { txt } from '@/use/txt'
import { computed, ref, reactive, watch, watchEffect, onMounted, inject } from "vue"
import {useQuery, useLazyQuery, useResult, useMutation, DefaultApolloClient} from "@vue/apollo-composable"
import mainQuery from '@/graphql/main.query.gql'
import customersQuery from '@/graphql/customers.gql'
import customerQuery from '@/graphql/customer.query.gql'
import productsQuery from '@/graphql/products.query.gql'
import {gql} from "@apollo/client"
import Menu from '@/components/Menu'
import {apolloClient} from "./main";

export default {
  setup(props) {

    const baseUrl = inject('baseUrl')

    const { cart, getCartItems, settings, prefs, me, user, customer_id, categories, ch_order, products, message, log_action, eventEmitter, eventListener } = useCart()

    // console.log(window.innerWidth)
    const smallScreen = ref(window.innerWidth < 700)
    window.addEventListener('resize', () => {
      smallScreen.value = (window.innerWidth < 700)
    })

    if (settings.value.message && !user.value) message('Дорогі друзі!', settings.value.message, 'info', 0)

    // watchEffect(() => {
    //   if (document.querySelector('#top_benefit')) console.log('top_benefit here')
    // })

    const mainCategories = computed(() => categories.value ? categories.value.filter(i => i.parent_id == 0 || i.parent_id == 67) : null)

    function handleSelect(key) {
      if (key && key[0] !== '!') router.push({path: key})
    }

    const loginDialog = ref(false)
    const loginLoading = ref(null)
    const loginError = ref(null)

    const form = reactive({ email: '', password: '', password_confirm: '' })

    async function signIn () {
      if (loginLoading.value) return
      loginError.value = false
      loginLoading.value = true
      await axios.get('/sanctum/csrf-cookie')
      await axios.post('/login', form).then(r => {
        // console.log('R: '+r)
        getMe()
      }).catch(e => {
        loginDialog.value = true
        let err = { 403: 'login_failed', 429: 'too_many' }
        loginError.value = err[e.response.status] ? err[e.response.status] : 'wrong'
        message(txt[prefs.lang].error, txt[prefs.lang][loginError.value], 'warning')
        // console.log('E: '+e)
        axios.post('/api/error', { creds: form.email, type: 2, error: e.response.status })
      })

      loginLoading.value = false
    }

    function getMe() {
      // loginDialog.value = false
      const { result, error: loginError } = useQuery(mainQuery, {}, { fetchPolicy: "network-only" })
      watchEffect(() => {
        me.value = result && result.value ? result.value.me : null
      })
    }

    watch(me, () => {
      loginDialog.value = (me && me.value) || (user && user.value) ? false : true
      if (me.value) message('Hi :)', 'Hi, '+me.value.firstname+'! 🎉', 'success')
    })

    // watch(loginError, () => {
    //   if (loginError.value) message(txt[prefs.lang].login_failed, txt[prefs.lang].try_again, 'warning')
    // })

    function socialLogin(provider) {
      loginError.value = false
      let win = window.open(baseUrl+'login/'+provider, 'popupName', 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=500,height=500,left=500,top=100')

      let pollTimer = setInterval(function() {
        if (win && win.closed) {
          clearInterval(pollTimer)
          // loginDialog.value = false
          getMe()
        }
      }, 100)

    }

    function logout() {
      axios.get('/sanctum/csrf-cookie').then(() => {
        axios.get('/logout').then(r => {
          me.value = null
          localStorage.removeItem('fd')
          // getMe() // ?
          // me.value = response.data
        }).catch(e => {
          // me.value = null
          // console.log('logout error')
          message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'error')
        })
      })
    }


    const isFilledWrong = computed(() => {
      if (!/^\S+@\S+\.\S+$/.test(form.email)) return 'wrong_email';
      if (loginDialog.value === 'signUp') return form.password.length < 8 ? 'password_short' : form.password !== form.password_confirm ? 'password_failed' : false
      return false
    })


    const signUpError = ref(false)

    // registration
    async function signUp () {
      if (loginLoading.value || isFilledWrong.value) return
      signUpError.value = false
      loginLoading.value = true
      await axios.get('/sanctum/csrf-cookie')
      await axios.post('/register', form).then(r => {
        // console.log('R: '+r)
        message(txt[prefs.lang].thanks, txt[prefs.lang].signed_up, 'success')
        loginDialog.value = 'signUp_'
      }).catch(e => {
        let err = { 409: "mail_exists", 422: "pls_check", 429: "too_many" }
        signUpError.value = err[e.response.status] ? err[e.response.status] : 'wrong'
        message(txt[prefs.lang].error, txt[prefs.lang][signUpError.value], 'warning')
        // loginDialog.value = 3
        // console.log('E: '+e)
        axios.post('/api/error', { creds: form.email, type: 4, error: e.response.status })
      })
      loginLoading.value = false
    }


    const resetError = ref(false)

    async function reset(email) {
      if (!me.value && (loginLoading.value || isFilledWrong.value)) return
      loginLoading.value = true
      resetError.value = false
      await axios.get('/sanctum/csrf-cookie')
      await axios.post('/reset_mail', { email: email }).then(r => {
        // console.log('R: '+r)
        message(txt[prefs.lang].reset, txt[prefs.lang].check_mail, 'success')
        loginDialog.value = 'reset_'
      }).catch(e => {
        let err = { 404: "wrong_email", 409: "mail_failed", 422: "wrong_email", 429: "too_many" }
        resetError.value = err[e.response.status] ? err[e.response.status] : 'wrong'
        message(txt[prefs.lang].error, txt[prefs.lang][resetError.value], 'warning')
        // console.log('E: '+e)
        axios.post('/api/error', { creds: form.email, type: 6, error: e.response.status })
      })
      loginLoading.value = false
    }


    if (user.value) {

      const { result: customerRes } = useQuery(customerQuery, { id: customer_id.value, skip: !!customer_id.value }, { fetchPolicy: "network-only" })

      watchEffect(() => {
        me.value = customerRes && customerRes.value ? customerRes.value.customer : null
      })

    }

    const { result, refetch } = useQuery(customersQuery)
    function getCustomers(query) {
        refetch({ global: query })
    }
    const customers = useResult(result, null, data => data.customers.data)

    const customerDialog = ref(false)
    const customer = ref(null)

    function chooseCustomer() {
      localStorage.removeItem('fd')
      customer_id.value = customer.value
      customerDialog.value = false
      const { result } = useQuery(customerQuery, { id: customer_id.value, skip: !!customer_id.value }, { fetchPolicy: "network-only" })
      customer.value = null
      watchEffect(() => {
        me.value = result && result.value ? result.value.customer : null
      })
    }

    function clearCustomer() {
      localStorage.removeItem('fd')
      customer_id.value = customer.value = me.value = null
    }

    const offline = ref(false)
    window.addEventListener('offline', () => {
      message('Oops!', txt[prefs.lang].offline, 'warning')
      offline.value = true
    })
    window.addEventListener('online', () => {
      message('Ура! :)', txt[prefs.lang].online, 'success')
      offline.value = false
    })

    const whaitavail = ref(false)

    function guest_checkout() {
      apolloClient.query({ query: productsQuery, fetchPolicy: "network-only" }).then(r => {
        products.value = r.data.products
        loginDialog.value = false
        router.push({ path: '/checkout', query: { guest: true, whaitavail: whaitavail.value } })
      })
    }

    // function clck(e) {
    //     e.preventDefault()
    //     console.log(e)
    //     log_action({ type: 'CLICK', value: e.target.href })
    // }

    onMounted(() => {
      // cross-tab cart
      window.addEventListener('storage', () => {
        // console.log('storage event')
        cart.items = []
        JSON.parse(localStorage.getItem('cart')).forEach(function (item, i) {
          if(item) cart.items[i] = item
          // else cart.items[i] = null // не работает при очистке корзины
          // else delete cart.items[i] // плохо
        })
        // customer.value = localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : null
        // chooseCustomer()
        // console.log(cart.items)
      })

        eventEmitter.on('showCartEventFooter', showCartEventFooter);
        // function showCartEventFooter() {
        //   let el = document.getElementById('cartEventFooter');
        //     el.style.display = 'block';
        //     setTimeout(() => {
        //         el.style.display = 'none';
        //     }, 2000)
        // }

      document.addEventListener('copy', function () {
        let sel = window.getSelection().toString().trim()
        let l = sel.length
        if (l < 2) return;
        if (l > 255) sel = sel.slice(0,125)+'<<|>>'+sel.slice(-125)
        log_action({ type: 9, value: sel })
      })

      document.querySelectorAll('.extls').forEach((i) => {
        i.addEventListener('click', e => {
          e.preventDefault()
          // console.log(e.target.href)
          log_action({ type: 3, value: e.target.href })
          let target = e.target.target.length ? '_blank' : '_self'
          window.open(e.target.href, target)
        })
      })
    })

    watchEffect(() => {
        if (loginDialog.value) log_action({ type: 4, value: loginDialog.value })
    })

      // showCartEventFooter()
    const cartEventFooter = ref(false)

    function showCartEventFooter() {
        console.log('Event received: showMessage');
        cartEventFooter.value = true;
        setTimeout(() => {
            cartEventFooter.value = false;
        }, 6000)
    }

    return { cart, settings, prefs, me, user, categories, mainCategories, handleSelect, logout, txt, router, ch_order, form, signIn, socialLogin, loginDialog, loginLoading, loginError, isFilledWrong, customerDialog, customers, customer, chooseCustomer, clearCustomer, smallScreen, offline, getCustomers, getMe, guest_checkout, whaitavail, signUp, signUpError, reset, resetError, baseUrl, cartEventFooter }
  },
  components: {
    Menu,
    // Catalog,
    // Cart
  },
  props: {
    // products: Object
  }
}
</script>

<style src="@/main.css"></style>